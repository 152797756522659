/**
 * @module Agent Status Hooks
 * @description
 */
import { getMoment, parseTimeString, getDayOfWeek } from '@/js/utils/time';

// const url = 'https://external.tools.rvapps.io/company_hours?companyID=73';
const staticHours = [
  { /* Sunday */
    dayOfWeek: 1,
    openTime: '09:00:00',
    closeTime: '19:00:00'
  },
  { /* Monday */
    dayOfWeek: 2,
    openTime: '08:00:00',
    closeTime: '22:00:00'
  },
  { /* Tuesday */
    dayOfWeek: 3,
    openTime: '08:00:00',
    closeTime: '22:00:00'
  },
  { /* Wednesday */
    dayOfWeek: 4,
    openTime: '08:00:00',
    closeTime: '22:00:00'
  },
  { /* Thursday */
    dayOfWeek: 5,
    openTime: '08:00:00',
    closeTime: '22:00:00'
  },
  { /* Friday */
    dayOfWeek: 6,
    openTime: '08:00:00',
    closeTime: '22:00:00'
  },
  { /* Saturday */
    dayOfWeek: 7,
    openTime: '08:00:00',
    closeTime: '19:00:00'
  }
];

/**
   * @function checkStatus
   * @description Compare datetime to see if open
   */
const checkStatus = ( today ) => {
  if ( today && today.openTime !== undefined && today.closeTime !== undefined ) {
    // Parse the time string
    const openTime = parseTimeString( today.openTime );
    const closeTime = parseTimeString( today.closeTime );

    // number of month & day
    const date = new Date();
    const month = date.getMonth();
    const day = date.getDate();

    // Holiday hours for December
    if ( month === 11 ) {
      // 24th & 31st: Open at 8am EST, close at 6pm EST
      if ( day === 24 || day === 31 ) {
        openTime.hour = 8;
        closeTime.hour = 18;
      }

      // 25th: Closed
      if ( day === 25 ) {
        openTime.hour = 0;
        closeTime.hour = 0;
      }
    }

    // Compare time strings to current time.
    return getMoment().isBetween( openTime, closeTime, 'minute' );
  }
};

/**
   * @function fetchStatus
   * @description Get the hours of our call center
   */
const fetchStatus = () => {
  // const status = await fetch( url )
  //   .then( ( res ) => res.json() )
  //   .then( ( data ) => {
  //     const today = data.hours[ 0 ];

  //     return checkStatus( today );
  //   } )
  //   .catch( () => {
  //     const today = staticHours[ getDayOfWeek() ];

  //     return checkStatus( today );
  //   } );

  // return status;

  const today = staticHours[ getDayOfWeek() ];

  return checkStatus( today );
};


/**
 * @function useAgentStatus
 * @description Our agent status API
 */
export const useAgentStatus = () => fetchStatus;

export default useAgentStatus;
